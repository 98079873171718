@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/normalize";
@import "./assets/scss/variables";
@import "./assets/scss/buttons";
@import "./assets/scss/default-colors";
@import "./assets/scss/forms";
@import "./assets/scss/table";
@import "./assets/scss/pagination";
@import "./assets/scss/others";

html,
body {
  font-size: 16px;
  font-family: $font-family;
  background-color: $background-color;
}

ngb-typeahead-window {
  max-height: 400px !important;
  overflow-y: auto !important;
}

// .btn-primary,
// .btn-primary:disabled,
// .btn-primary:hover,
// .btn-primary:active,
// .btn-primary:visited,
// .btn-primary:focus {
//   background-color: #004f80;
//   border-color: #004f80;
// }

.container {
  width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #004f80;
  border-color: #004f80;
}

.dot-gray {
  height: 7px;
  width: 7px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
  vertical-align: middle;
}

.dot-red {
  height: 7px;
  width: 7px;
  background-color: rgb(245, 75, 75);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
  vertical-align: middle;
}

.dot-green {
  height: 7px;
  width: 7px;
  background-color: rgb(3, 184, 3);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
  vertical-align: middle;
}

.dot-red-large {
  height: 10px;
  width: 10px;
  background-color: rgb(245, 75, 75);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot-green-large {
  height: 10px;
  width: 10px;
  background-color: rgb(3, 184, 3);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot-orange {
  height: 7px;
  width: 7px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
  vertical-align: middle;
}

.dot-black {
  height: 7px;
  width: 7px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  display: inline-block;
  margin-right: 5px;
  margin-top: -5px;
  vertical-align: middle;
}

.sair {
  color: blue !important;
}

.has-feedback .form-control {
  padding-right: 34px;
  position: relative;
}

.has-feedback .form-control.input-sm,
.has-feedback.form-group-sm .form-control {
  padding-right: 30px;
}

.has-feedback .form-control.input-lg,
.has-feedback.form-group-lg .form-control {
  padding-right: 46px;
}

.form-control-feedback {
  line-height: 34px !important;
  position: absolute;
  right: 15px;
  top: 29px;
}

.input-sm + .form-control-feedback,
.form-horizontal .form-group-sm .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px !important;
}

.input-lg + .form-control-feedback,
.form-horizontal .form-group-lg .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px !important;
}

.has-feedback label.sr-only ~ .form-control-feedback,
.has-feedback label.sr-only ~ div .form-control-feedback {
  top: 0;
}

a[ng-click],
a {
  cursor: pointer;
}

.form__input {
  font-size: 16px;
  box-shadow: 5px 5px 5px rgba(92, 92, 92, 0.4);
}

.form__input:focus {
  font-size: 16px;
  box-shadow: 5px 5px 5px rgba(92, 92, 92, 0.4);
}

.lbl__thinner {
  color: #7f7f7f;
  font-weight: lighter;
}

.lbl__thinner__big {
  font-size: 16px;
  color: #7f7f7f;
}

.anchor_big {
  font-size: 16px;
}

.lbl__big {
  font-size: 16px;
  color: black;
}

.lbl_blue {
  font-size: 13px;
  font-weight: 500;
  color: #015080;
  font-style: normal;
}

// .btn__secondary,
// .btn__default,
// .btn__primary {
//   width: 120px;
//   font-size: 12px;
// }

.btn__secondary {
  font-size: 12px;
  font-style: normal;
  border: 0.05rem solid black;
}

.btn__default {
  background-color: #fff;
  border-color: #000;
}

.btn__primary,
.btn__primary:hover {
  background-color: #084c7d;
  color: #fff;
}

.btn__default__large,
.btn__default__large:hover {
  background-color: #fff;
  border-color: #000;
}

.btn__success,
.btn__success:hover {
  background-color: #17a471;
  color: #fff;
}

.btn__danger,
.btn__danger:hover {
  background-color: #d9001b;
  color: #fff;
}

.btn__warn__light,
.btn__warn__light:hover {
  background-color: #ffffc3;
  border-color: #a36717;
  color: #000;
  font-weight: 600;
}

.vertical__line {
  border-left: 1px solid #d7d7d7;
  height: 130px;
  position: absolute;
  left: 40%;
}

.title {
  font-size: 18px;
  position: relative;
  text-transform: uppercase;
  font-weight: bolder;
  color: #015080;
  margin-top: 20px;
}

.subtitle {
  font-weight: bolder;
  color: #7f7f7f;
}

.blue__title {
  font-size: 18px;
  position: relative;
  text-transform: uppercase;
  font-weight: bolder;
  font-weight: bolder;
  color: #015080;
  margin-top: 20px;
}

.blue__subtitle {
  font-size: 14px;
  font-weight: 700;
  color: #015080;
}

.btn-input {
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-color: #d2cdca;
  border-left: none;
  &:hover {
    background-color: #b9b9b9;
    border-color: #b9b9b9;
  }
}

.bs-datepicker-head {
  background-color: #015080 !important;
}

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

.tooltip-inner {
  max-width: 310px !important;
  width: max-content;
  text-align: unset;
}

.bg-warning {
  background-color: #b08504 !important;
}

.modal-lg {
  width: 800px;
}

.link {
  color: blue;
  text-decoration: underline;
  > p {
    color: blue;
  }
}

@media (max-width: 767px) {
  .search-by-bale-content-responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button-google-maps-responsive {
    max-width: 350px;
    width: 100%;
  }
  .NgxEditor__Wrapper {
    .NgxEditor__MenuBar {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .my-google-map {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
