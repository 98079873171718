@import "src/assets/scss/colors";

.btn-primary {
  background: $primary-blue;
  border-radius: 0;
  padding: 5px 10px;
  border-color: $primary-blue;
  font-weight: bold;
  border: 2px solid $primary-blue;
  width: 250px;
  font-size: 16px;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 16%);

  &:hover {
    color: $white;
    background-color: lighten($primary-blue, 10%);
    border-color: lighten($primary-blue, 10%);
  }

  &.disabled {
    color: #fff;
    background-color: $gray;
    border-color: $gray;
  }
}

.btn-outline-primary {
  border-radius: 0;
  padding: 5px 10px;
  color: $primary-blue;
  border-color: $primary-blue;
  font-weight: bold;
  border: 2px solid $primary-blue;
  width: 350px;
  font-size: 16px;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 16%);

  &:hover {
    color: $white;
    background-color: $primary-blue;
    border: 2px solid $primary-blue;
  }

  &.disabled {
    color: $gray;
    border-color: $gray;
  }
}

// .btn-outline-secondary {
//   border-radius: 0;
//   padding: 5px 10px;
//   font-weight: bold;
//   text-transform: lowercase;
//   font-size: 16px;

//   &.disabled {
//     color: $gray;
//     border-color: $gray;
//   }
// }

.btn-success {
  background: $success;
  border-radius: 0;
  padding: 5px 10px;
  border-color: $success;
  font-weight: bold;
  border: 2px solid $success;
  width: 200px;
  font-size: 16px;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 16%);

  &:hover {
    color: $white;
    background-color: lighten($success, 10%);
    border-color: lighten($success, 10%);
  }
  &.disabled {
    color: #fff;
    background-color: $gray;
    border-color: $gray;
  }
}

.btn-danger {
  background: $danger;
  border-radius: 0;
  padding: 5px 10px;
  border-color: $danger;
  font-weight: bold;
  border: 2px solid $danger;
  width: 200px;
  font-size: 16px;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 16%);

  &:hover {
    color: $white;
    background-color: lighten($danger, 10%);
    border-color: lighten($danger, 10%);
  }

  &.disabled {
    color: #fff;
    background-color: $gray;
    border-color: $gray;
  }
}

.btn-default {
  background: rgba(244, 240, 239, 0.5);
  border-radius: 0;
  padding: 5px 10px;
  border-color: $gray;
  font-weight: bold;
  border: 2px solid $gray;
  width: 200px;
  font-size: 16px;
  box-shadow: 0px 3px 2px rgb(0 0 0 / 16%);

  &:hover {
    color: #918E8A;
    background-color: lighten(rgba(244, 240, 239, 0.5), 10%);
    border-color: lighten(rgba(244, 240, 239, 0.5), 10%);
  }

  &.disabled {
    color: #918E8A;
    background-color: rgba(244, 240, 239, 0.5);
    border-color: $gray;
  }
}