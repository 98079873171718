@import "src/assets/scss/colors";

$dot-menu-size: 45px;
$dot-menu-dot-size: 4px;
$dot-menu-dot-color: #8a8a8c;

.circle-orange {
  color: #c69214;
}

.circle-green {
  color: #1f7052;
}

.circle-red {
  color: #ce543a;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
  display: flex;
  align-items: center;

  /* Preto Terciário */

  color: #6a6969;
}

p {
  font-style: normal;
  font-weight: 100 !important;
  font-size: 14px;
  line-height: 160%;

  display: flex;
  align-items: center;

  /* Preto Terciário */

  color: #918e8a;
}

.p-black {
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;

  /* Preto */
  color: $black;
}

hr {
  $margin: -9.5%;
  margin-left: $margin;
  margin-right: $margin;
}

/* Dropdown for reports */
#dropdownButtonActions::after {
  content: none;
}

.button-actions {
  & .item-action {
    display: flex;
    justify-content: center;
    padding: 5px 15px;
    border-bottom: 1px solid #d9d9d9;

    &:hover {
      cursor: pointer;
      background-color: rgb(234, 234, 234);
    }

    .paper-plane-icon {
      color: #084c7d;
    }

    .ban-icon {
      color: #ce543a;
    }
  }
}

#buttonActions {
  padding: 0px;
}

.dot-menu__label {
  position: relative;
  display: block;
  width: $dot-menu-size;
  height: $dot-menu-size;
  border-radius: $dot-menu-size;
  margin: 0 auto;
  transition: background-color 0.2s linear;
  cursor: pointer;
}

.dot-menu__label {
  text-indent: -9999em;
}

.dot-menu__label:before,
.dot-menu__label:after,
.dot-menu__label span:before {
  content: "";
  position: absolute;
  left: $dot-menu-size / 2 - $dot-menu-dot-size / 2;
  width: $dot-menu-dot-size;
  height: $dot-menu-dot-size;
  border-radius: $dot-menu-dot-size;
  background-color: $dot-menu-dot-color;
  transition: 0.4s;
}

.dot-menu__label:before {
  top: ($dot-menu-size / 2 - $dot-menu-dot-size / 2) + ($dot-menu-dot-size * 1.5);
}

.dot-menu__label:after {
  top: ($dot-menu-size / 2 - $dot-menu-dot-size / 2) - ($dot-menu-dot-size * 1.5);
}

.dot-menu__label span:before {
  top: ($dot-menu-size / 2 - $dot-menu-dot-size / 2);
}

.dot-menu__label:nth-of-type() {
  ~ .dot-menu__label:before {
    transform: rotate(224deg) translate(14px, -1px);
    transform-origin: center center;
    width: 60%;
  }

  ~ .dot-menu__label:after {
    transform: rotate(136deg) translate(14px, 1px);
    transform-origin: center center;
    width: 60%;
  }
}

@media (max-width: 768px) {
  .p-black {
    justify-content: center;
  }
}
