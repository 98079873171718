$primary-blue: #084c7d;
$secondary-blue: #1767a1;
$secondary-yellow: #c69214;
$black: #494848;
$gray: #918e8a;
$white: #fffefe;
$success: #1f7052;
$danger: #ce543a;

$text-primary: $black;
$text-primary: #6a6560;
$border-color: #d2cdca;
$background-color: #f6f4f3;
$border-input: #918E8A;

$table-head-background: #f6f4f3;

$light-gray: #f6f4f3;
