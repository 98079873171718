@import "src/assets/scss/colors";

ngb-pagination {
  .pagination {
    margin-top: 15px;
    margin-bottom: 15px;
    .page-item {
      height: 30px;
      max-width: 70px;
      margin: auto 5px;
      &.disabled {
        cursor: not-allowed;
        .page-link {
          color: $black;
          background-color: lighten($gray, 25%);
          border-color: lighten($gray, 25%);
        }
      }
      &.active {
        .page-link {
          color: $white;
          background-color: $primary-blue;
          border-color: $primary-blue;
          border-radius: 4px;
        }
      }
      .page-link {
        color: $primary-blue;
        background-color: #cfdbe3;
        border: 1px solid #cfdbe3;
        font-weight: bold;
      }
    }
  }
}

.current-page-collection,
.page-size {
  height: 30px;
  font-size: 1.2rem;
  margin: auto 5px;
}
