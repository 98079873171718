.table {
  thead,
  .thead-light {
    height: 50px;
    th {
      color: $gray;
      background-color: $table-head-background;
      border-color: $table-head-background;
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
      text-align: center;
    }
  }
  &.no-header {
    th {
      background-color: $white;
      border-color: $white;
    }
  }

  tbody {
    tr {
      width: 100%;
      height: 60px;
      border-color: #ffffff;
      td,
      th {
        border: none;
        font-size: 11px;
        font-weight: normal;
        vertical-align: middle;
        text-align: center;
        font-style: normal;
        color: #8a8a8c;

        &.link {
          a {
            color: $gray;
          }
        }
      }
      &:nth-child(even) {
        background: #f6f6f6;

        td {
          &.link {
            background: #d2cdca;
            a {
              color: $gray;
            }
          }
        }
      }
      &:nth-child(odd) {
        background: #ffffff;

        td {
          &.link {
            background: #f6f4f3;
            a {
              color: $gray;
            }
          }
        }
      }
    }
  }
}
