@import "src/assets/scss/colors";

form {
  .form-group {
    label {
      font-size: 16px;
      font-weight: lighter;
      color: $black;
    }
    .form-control {
      font-size: 16px;
      box-shadow: none;
      border-radius: 0;
      height: calc(2em + 0.75rem + 2px);
      border-color: $border-color;
      color: $gray;
    }
  }
}

// .btn-primary {
//   background: $primary-blue;
//   border-radius: 0;
//   padding: 10px 10px;
//   border-color: $primary-blue;
//   font-weight: bold;
//   border: 2px solid $primary-blue;

//   &:hover {
//     color: $white;
//     background-color: lighten($primary-blue, 10%);
//     border-color: lighten($primary-blue, 10%);
//   }

//   &.disabled {
//     color: #fff;
//     background-color: $gray;
//     border-color: $gray;
//   }
// }

// .btn-outline-primary {
//   border-radius: 0;
//   padding: 10px 10px;
//   color: $primary-blue;
//   border-color: $primary-blue;
//   font-weight: bold;
//   border: 2px solid $primary-blue;

//   &:hover {
//     color: $white;
//     background-color: lighten($primary-blue, 10%);
//     border-color: lighten($primary-blue, 10%);
//   }

//   &.disabled {
//     color: $gray;
//     border-color: $gray;
//   }
// }

// .btn-success {
//   background: $success;
//   border-radius: 0;
//   padding: 10px 10px;
//   border-color: $success;
//   font-weight: bold;

//   &:hover {
//     color: $white;
//     background-color: lighten($success, 10%);
//     border-color: lighten($success, 10%);
//   }
// }

// .btn-success {
//   background: $danger;
//   border-radius: 0;
//   padding: 10px 10px;
//   border-color: $danger;
//   font-weight: bold;

//   &:hover {
//     color: $white;
//     background-color: lighten($danger, 10%);
//     border-color: lighten($danger, 10%);
//   }
// }
